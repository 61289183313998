import React, { useEffect } from "react";
import { Button } from "reactstrap";
import { FaPlus } from "react-icons/fa";
import { FaMinus } from "react-icons/fa";
import { FaHome } from "react-icons/fa";

const btnStyle = {
  position: "relative",
  marginBottom: "5px",
  zIndex: "2",
};

const containerStyle = {
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  zIndex: "7",
  right: "15px",
  top: "100px",
};

export default function MapControls(props) {
  useEffect(() => {}, []);

  return (
    <div style={containerStyle}>
      {props.tools.center && (
        <Button
          style={btnStyle}
          color="secondary"
          size="md"
          onClick={() =>
            props.map
              .getView()
              .animate({ zoom: 14 }, { center: props.map.get("mapCenter") })
          }
        >
          <FaHome />
        </Button>
      )}
      {props.tools.zoom && (
        <Button
          style={btnStyle}
          color="secondary"
          size="md"
          onClick={() =>
            props.map
              .getView()
              .animate({ zoom: props.map.getView().getZoom() + 1 })
          }
        >
          <FaPlus />
        </Button>
      )}
      {props.tools.zoom && (
        <Button
          style={btnStyle}
          color="secondary"
          size="md"
          onClick={() =>
            props.map
              .getView()
              .animate({ zoom: props.map.getView().getZoom() - 1 })
          }
        >
          <FaMinus />
        </Button>
      )}
    </div>
  );
}
