import React from "react";
import { useParams, Link } from "react-router-dom";
import AuthStore from "../stores/AuthStore";

function Header() {
  let { projectName } = useParams();
  let project = projectName ? projectName : AuthStore.project.slug;

  if (AuthStore.project.token) {
    return (
      <wb-header small wblogo class="wb-header-on-top">
        <wb-header-mainnav>
          <wb-navitem>
            <Link to={`/${project}`}>Kaart</Link>
          </wb-navitem>
          <wb-navitem>
            <Link to={`/${project}/matrix`}>HB-matrix</Link>
          </wb-navitem>
          <wb-navitem>
            <Link to={`/${project}/data`}>Verplaatsingsgedrag</Link>
          </wb-navitem>
          <wb-navitem>
            <Link to={`/over`}>Over</Link>
          </wb-navitem>
          <wb-navitem>
            <Link to={`/${project}/contact`}>Contact</Link>
          </wb-navitem>
          <wb-navitem
            onClick={() => {
              AuthStore.signout();
            }}
          >
            <Link to={`/${project}/login`}>
              <span className="material-icons">logout</span>
            </Link>
          </wb-navitem>
        </wb-header-mainnav>
      </wb-header>
    );
  }

  return (
    <wb-header small wblogo class="wb-header-on-top">
      <wb-header-mainnav>
        <wb-navitem>
          <Link to={`/`}>FietsMonitor aanvragen</Link>
        </wb-navitem>
        <wb-navitem>
          <Link to={`/over`}>Over</Link>
        </wb-navitem>
      </wb-header-mainnav>
    </wb-header>
  );
}

export default Header;
