import React from "react";

const calculateUpperClass = function (min, max) {
  let average = (min + max) / 2;
  return Math.round(average / 100) * 100;
};

const legendUnitStyle = {
  fontSize: "small",
  opacity: 0.7,
};

export default function MapLegendItems(props) {
  let legendItems;

  if (props.map_layer.values) {
    legendItems = props.map_layer.values.map((value, key) => (
      <div className="d-flex flex-row pt-3" key={key}>
        <div
          style={{
            backgroundColor: props.map_layer.style_attributes.colors[key],
            width: "30px",
            height: "20px",
            marginTop: "4px",
          }}
        ></div>
        {key === 0 ? (
          <div className="pl-2">
            {props.map_layer.style_attributes.labels[key]}{" "}
            <span style={legendUnitStyle}>
              {" "}
              (circa {calculateUpperClass(0, value)})
            </span>
          </div>
        ) : (
          <div className="pl-2" style={legendItems}>
            {props.map_layer.style_attributes.labels[key]}{" "}
            <span style={legendUnitStyle}>
              (circa{" "}
              {calculateUpperClass(props.map_layer.values[key - 1], value)})
            </span>
          </div>
        )}
      </div>
    ));
  }

  return <div className="pt-1">{legendItems}</div>;
}
