import React, { useEffect } from "react";
import "ol/ol.css";

const loadStyle = {
  position: "absolute",
  bottom: "0",
  left: "0",
  height: "10",
  boxShadow: "0px 0px 1px 4px rgb(0,94,118)",
  width: "0",
  transition: "width 250ms",
};

export default function ProgressBar(props) {
  useEffect(() => {
    function Progress(el) {
      this.el = el;
      this.loading = 0;
      this.loaded = 0;
    }

    Progress.prototype.addLoading = function () {
      if (this.loading === 0) {
        this.show();
      }
      ++this.loading;
      this.update();
    };

    Progress.prototype.addLoaded = function () {
      const this_ = this;
      setTimeout(function () {
        ++this_.loaded;
        this_.update();
      }, 100);
    };

    Progress.prototype.update = function () {
      const width = ((this.loaded / this.loading) * 100).toFixed(1) + "%";
      this.el.style.width = width;
      if (this.loading === this.loaded) {
        this.loading = 0;
        this.loaded = 0;
        const this_ = this;
        setTimeout(function () {
          this_.hide();
        }, 500);
      }
    };

    Progress.prototype.show = function () {
      this.el.style.visibility = "visible";
    };

    Progress.prototype.hide = function () {
      if (this.loading === this.loaded) {
        this.el.style.visibility = "hidden";
        this.el.style.width = 0;
      }
    };

    const progress = new Progress(document.getElementById("progress"));

    props.source.on("tileloadstart", function () {
      progress.addLoading();
    });

    props.source.on("tileloadend", function () {
      progress.addLoaded();
    });
  }, []);

  return (
    <div>
      <div style={loadStyle} id="progress"></div>
    </div>
  );
}
