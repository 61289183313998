import React from "react";
import Header from "../components/Header.js";
import { Container, Row, Col, Tab, Tabs, Figure } from "react-bootstrap";
import overheader from "../assets/overheader.png";
import bike from "../assets/bike.png";

const Over = () => {
  return (
    <div>
      <Header></Header>
      <wb-hero img={overheader} gradient="true" className="over-hero">
        <p style={{ lineHeight: "1.5" }}>
          De FietsMonitor visualiseert de fietsstromen van jouw projectgebied op
          een kaart. Dit is een belangrijke eerste stap om een beeld te krijgen
          van waar er gefietst wordt in een gemeente. De nadruk ligt ook op
          &#96;eerste stap&#180;, omdat we op basis van projectspecifieke vragen
          en data dit eerste beeld verder kunnen verbeteren, toespitsen en
          uitbouwen aan de hand van een klantvraag. Deze eerste stap van de
          FietsMonitor is vooral{" "}
          <strong style={{ color: "#8CD2F7" }}>beschrijvend</strong>{" "}
          (visualiseren van bestaande data), maar kan een aanzet zijn tot de
          vervolgstappen{" "}
          <strong style={{ color: "#8CD2F7" }}>diagnosticerend</strong>{" "}
          (knelpunten identificeren) en{" "}
          <strong style={{ color: "#8CD2F7" }}>voorspellend</strong>{" "}
          (toekomstige situatie berekenen) en eventueel{" "}
          <strong style={{ color: "#8CD2F7" }}>voorschrijvend</strong>{" "}
          (parametrisch ontwerpen). Denk bij deze stappen aan het in beeld
          brengen van bestaande fietsstromen, het inventariseren van potentiële
          capaciteitsknelpunten en het bepalen van de impact van een nieuwe
          fietsverbinding. Zodoende ondersteunt de FietsMonitor in projecten met
          betere cijfermatige onderbouwingen. De mogelijkheden op een rijtje:
        </p>
      </wb-hero>
      <Container>
        <Tabs
          defaultActiveKey="beschrijvend"
          id="uncontrolled-tab-example"
          className="mb-3"
          fill
        >
          <Tab eventKey="beschrijvend" title="1 - Beschrijvend">
            <p>
              De FietsMonitor brengt de bestaande situatie in beeld visualiseren
              van bestaande fietsgerelateerde data. De FietsMonitor biedt
              allereerst een platform om bestaande fietsgerelateerde databronnen
              te visualiseren. Enkele voorbeelden zijn hieronder genoemd maar er
              zijn nog veel meer databronnen toe te voegen of datacombinaties te
              maken:
            </p>

            <Row className="align-items-center">
              <Col>
                <div>
                  <h5>Fietsstromen in gemeente en provincie</h5>
                  <p>
                    De FietsMonitor geeft een eerste beeld van fietsstromen in
                    een gemeente of provincie op basis van data uit OnDerweg in
                    Nederland (ODiN). Door deze data in een verkeersmodel te
                    verwerken tot een schatting van fietsintensiteiten, wordt
                    een eerste beeld gegeven van te verwachten fietsstromen in
                    het gehele fietsnetwerk.
                  </p>
                </div>
              </Col>
              <Col>
                <Figure>
                  <Figure.Image src="/assets/beschrijvend_1.png" />
                </Figure>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col>
                <Figure>
                  <Figure.Image src="/assets/beschrijvend_2.png" />
                </Figure>
              </Col>
              <Col>
                <div>
                  <h5>Fietsrelaties specifieke doelgroepen </h5>
                  <p>
                    De fietsintensiteiten kunnen uitgesplitst worden naar
                    specifieke doelgroepen zoals fietsforenzen en scholieren;
                    bijvoorbeeld om te kunnen inzetten op veilige schoolroutes.
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col>
                <div>
                  <h5>Specifiek gebruik infrastructuur (selected link)</h5>
                  <p>
                    Omdat bij het bepalen van de fietsintensiteiten ook een
                    routekeuzemodel wordt gebruikt, kan de FietsMonitor ook
                    inzicht geven in welke fietsers gebruik maken van een
                    specifieke fietsverbinding in het fietsnetwerk, een
                    zogenaamde selected link-analyse.
                  </p>
                </div>
              </Col>
              <Col>
                <Figure>
                  <Figure.Image src="/assets/beschrijvend_3.png" />
                </Figure>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col>
                <Figure>
                  <Figure.Image src="/assets/beschrijvend_4.png" />
                </Figure>
              </Col>
              <Col>
                <div>
                  <h5>
                    Ontwerp fietsinfrastructuur (breedte en verharding fietspad)
                  </h5>
                  <p>
                    De FietsMonitor kan worden aangevuld met data over het
                    wegontwerp (vanuit BGT), zodat de breedte en type verharding
                    getoond kan worden. Zodoende kan de kwaliteit van het
                    fietsnetwerk inzichtelijk gemaakt worden.
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col>
                <div>
                  <h5>Fiets(on)veiligheid</h5>
                  <p>
                    Ongevallen waarbij fietsers betrokken zijn geweest bieden in
                    de FietsMonitor inzicht in ongevalsconcentratie en daarmee
                    de verkeersveiligheid voor fietsers in het netwerk.
                  </p>
                </div>
              </Col>
              <Col>
                <Figure>
                  <Figure.Image src="/assets/beschrijvend_5.png" />
                </Figure>
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="diagnosticerend" title="2 - Diagnosticerend">
            <p>
              De FietsMonitor brengt potentiële knelpunten in kaart vergelijking
              van een wensbeeld met de bestaande situatie. Voortbordurend op het
              visualiseren van fiets gerelateerde data, biedt de FietsMonitor de
              mogelijkheid potentiële knelpunten in kaart te brengen. Door een
              wensbeeld of richtlijnen toe te voegen aan het fietsnetwerk en te
              bepalen in welke mate hieraan wordt voldaan, wordt het
              fietsnetwerk beoordeeld. Een (niet-uitputtend) lijstje van
              mogelijkheden:
            </p>
            <Row className="align-items-center">
              <Col>
                <div>
                  <h5>
                    Capaciteit (waar is een fietspad onvoldoende breed voor de
                    verwachte fietsstroom)
                  </h5>
                  <p>
                    Aan de hand van de fietsintensiteiten, de breedte van de
                    fietspaden en de richtlijnen van het CROW wordt bepaald over
                    de fietspaden breed genoeg zijn voor de fietsstroom die ze
                    verwerken. Zodoende wordt getoond waar bredere fietspaden
                    wenselijk zijn.
                  </p>
                </div>
              </Col>
              <Col>
                <Figure>
                  <Figure.Image src="/assets/diagnosticerend_1.png" />
                </Figure>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col>
                <Figure>
                  <Figure.Image src="/assets/diagnosticerend_2.png" />
                </Figure>
              </Col>
              <Col>
                <div>
                  <h5>
                    Comfort (waar voldoet de verharding niet aan het wensbeeld)
                  </h5>
                  <p>
                    Door de verharding van de fietsinfrastructuur tegen het
                    wensbeeld van bijvoorbeeld het gemeentelijk beleid af te
                    zetten, kan het comfortniveau van het fietsnetwerk in beeld
                    gebracht worden.
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col>
                <div>
                  <h5>
                    Fietsbereikbaarheid (hoeveel bestemmingen kan je binnen een
                    bepaalde afstand of tijd bereiken)
                  </h5>
                  <p>
                    De FietsMonitor toont een bereikbaarheidskaart waarin
                    getoond wordt welke bestemmingen bereikt kunnen worden
                    binnen een bepaald aantal minuten.
                  </p>
                </div>
              </Col>
              <Col>
                <Figure>
                  <Figure.Image src="/assets/diagnosticerend_3.png" />
                </Figure>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col>
                <Figure>
                  <Figure.Image src="/assets/diagnosticerend_4.png" />
                </Figure>
              </Col>
              <Col>
                <div>
                  <h5>
                    Ontbrekende schakels (waar is de fietsafstand via het
                    netwerk veel groter dan de hemelsbrede afstand)
                  </h5>
                  <p>
                    De FietsMonitor biedt inzicht in mogelijk ontbrekende
                    schakels in het fietsnetwerk door de hemelsbrede afstanden
                    van fietsverplaatsingen af te zetten tegen de afstanden via
                    het fietsnetwerk. Daar waar de afstanden via het netwerk
                    relatief veel groter zijn dan de hemelsbrede afstanden is
                    mogelijk spraken van een ontbrekende schakel.
                  </p>
                </div>
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="voorspellend" title="3 - Voorspellend">
            <p>
              De FietsMonitor rekent aan groeiscenario’s en variantenstudies
              bepalen van effecten van autonome of voorgenomen veranderingen in
              het fietsnetwerk en fietsgebruik.
            </p>
            <Row className="align-items-center">
              <Col>
                <div>
                  <h5>Effecten nieuwe fietsverbinding</h5>
                  <p>
                    In de FietsMonitor kan een nieuwe fietsverbinding ingetekend
                    worden om vervolgens te bepalen wat de impact van die nieuwe
                    verbinding is op de fietsstromen. Naast veranderende routes
                    bepaalt de FietsMonitor ook het groeiende fietsgebruik door
                    het invullen van de latente vraag.
                  </p>
                </div>
              </Col>
              <Col>
                <Figure>
                  <Figure.Image src="/assets/voorspellend_1.png" />
                </Figure>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col>
                <Figure>
                  <Figure.Image src="/assets/voorspellend_2.gif" />
                </Figure>
              </Col>
              <Col>
                <div>
                  <h5>Toetsen ontwerpvarianten</h5>
                  <p>
                    Voortbordurend op de effecten van nieuwe verbindingen kan de
                    FietsMonitor helpen bij het toetsen van ontwerpvarianten.
                    Door verschillende ontwerpen van potentiële fietsnetwerken
                    door te rekenen en te vergelijken, ondersteunt de
                    FietsMonitor bij het maken van de meest effectieve ontwerp
                    van het fietsnetwerk.
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col>
                <div>
                  <h5>
                    Uitwisseling met andere modaliteiten (modal shift; in
                    ontwikkeling)
                  </h5>
                  <p>
                    Op basis van de netwerkkwaliteit van het fietsnetwerk in
                    vergelijking met de netwerken voor de auto en het openbaar
                    vervoer geeft de FietsMonitor inzicht in de modaliteitskeuze
                    in een stedelijke omgeving (modal split). Hierin kunnen ook
                    ontwikkelingen (zoals het groeiende gebruik van e-bikes) en
                    potentiële beleidsmaatregelen (zoals het voornemen om de
                    maximum snelheden in de stad te verlagen naar 30 kmh) worden
                    ingevoerd om de impact op het gebruik van de fiets te
                    bepalen (modal shift).
                  </p>
                </div>
              </Col>
              <Col>
                <Figure>
                  {/* <Figure.Image src="/assets/diagnosticerend_3.png" /> */}
                </Figure>
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="voorschrijvend" title="4 - Voorschrijvend">
            <Row className="align-items-center">
              <Col>
                <div>
                  <h5>Voorschrijvend; nog niet ontwikkeld</h5>
                  <p>
                    Mogelijk is de FietsMonitor op termijn in te zetten voor
                    parametrisch ontwerpen laten bepalen optimale
                    maatregelenpakket voor beleidsdoelen.
                  </p>
                </div>
              </Col>
            </Row>
          </Tab>
        </Tabs>
      </Container>
      <wb-hero gradient="true" className="over-hero" img={bike}>
        <p style={{ lineHeight: "1.5" }}>
          Potentieel zijn er veel meer vragen te beantwoorden door het inzetten
          van de FietsMonitor. Recentelijk is de FietsMonitor al voor{" "}
          <a
            href="https://digitalsolutions.witteveenbos.com/ruimtelijke-ontwikkeling-wonen-en-mobiliteit/mobiliteit/fietsmonitor"
            style={{ fontStyle: "italic", textDecoration: "underline" }}
          >
            verschillende projecten
          </a>{" "}
          ingezet. Mocht je een specifieke fietsgerelateerde vraag hebben,
          schroom dan vooral niet om contact op te nemen met het
          FietsMonitor-team via Contact of door te mailen naar{" "}
          <a
            href="mailto:fietsmonitor@witteveenbos.com"
            style={{ fontStyle: "italic", textDecoration: "underline" }}
          >
            fietsmonitor@witteveenbos.com
          </a>
        </p>
      </wb-hero>
    </div>
  );
};

export default Over;
