import React, { useRef, useEffect, useState } from "react";
import "ol/ol.css";
import Map from "ol/Map.js";
import View from "ol/View.js";
import MVT from "ol/format/MVT.js";
import VectorTileLayer from "ol/layer/VectorTile.js";
import VectorTileSource from "ol/source/VectorTile.js";
import { Stroke, Style, Fill, Text } from "ol/style.js";
import { createBasemap } from "../../utils/mapUtils.js";

// components
import MapControls from "./MapControls.js";
import RequestFrom from "./RequestForm.js";

const mapStyle = {
  position: "absolute",
  height: "100%",
  top: "0",
  left: "0",
  bottom: "0",
  right: "0",
};

const selectionStyle = new Style({
  fill: new Fill({
    color: "rgba(0,151, 217, 0.4)",
  }),
  stroke: new Stroke({
    color: "#005e76",
    width: 3,
  }),
});

const getText = function (label, resolution) {
  let text = label;

  if (resolution > 200) {
    text = "";
  }
  return text;
};

const labelStyle = function (feature, resolution) {
  return new Style({
    text: new Text({
      textAlign: "center",
      font: "12px verdana",
      text: getText(feature.get("gemeentenaam"), resolution),
      maxreso: 180,
    }),
  });
};

export default function ProjectAreaMap() {
  const mapEl = useRef(null);
  const [map, setMap] = useState(null);
  const [selected] = useState({});

  useEffect(() => {
    let gemeente = new VectorTileLayer({
      declutter: false,
      zindex: 30,
      source: new VectorTileSource({
        format: new MVT(),
        url: `${process.env.REACT_APP_TILESERVER_URL}/fm.gemeenten/{z}/{x}/{y}.pbf`,
      }),
    });

    let labels = new VectorTileLayer({
      declutter: true,
      zindex: 30,
      source: new VectorTileSource({
        format: new MVT(),
        url: `${process.env.REACT_APP_TILESERVER_URL}/fm.gem_label/{z}/{x}/{y}.pbf`,
      }),
      style: labelStyle,
    });

    const brt = createBasemap();

    let map = new Map({
      target: mapEl.current,
      controls: [],
      layers: [brt, labels],
      view: new View({
        center: [597724.15, 6850858.28],
        zoom: 8,
      }),
    });

    map.addLayer(gemeente);

    // fetch("../styles/gemeente.json").then(function (response) {
    //   response
    //     .json()
    //     .then(function (glStyle) {
    //       stylefunction(gemeente, glStyle, ["municipality"]);
    //     })
    //     .then(() => {
    //       map.addLayer(gemeente);
    //     });
    // });

    setMap(map);

    const selectionLayer = new VectorTileLayer({
      map: map,
      renderMode: "vector",
      source: gemeente.getSource(),
      style: function (feature) {
        if (feature.get("id") in selected) {
          return selectionStyle;
        }
      },
      declutter: true,
    });

    map.on("singleclick", (event) => {
      let feature = map.getFeaturesAtPixel(event.pixel)[0];

      if (!feature) {
        return;
      }

      let fid = feature.get("id");

      if (selected[fid]) {
        delete selected[fid];
      } else {
        for (const fid in selected) {
          delete selected[fid];
        }
        selected[fid] = feature;
      }
      selectionLayer.changed();
    });
  }, []);

  return (
    <div>
      {map && <MapControls map={map} tools={{ zoom: true, center: false }} />}
      <div style={mapStyle} ref={mapEl}></div>
      <RequestFrom project_area={selected} />
    </div>
  );
}
