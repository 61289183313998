import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  FormFeedback,
  Input,
  Label,
} from "reactstrap";

import api from "../services/api.js";

export default function ContactForm() {
  const [email, setEmail] = useState(null);
  const [name, setName] = useState(null);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState(null);
  const [isValid, setIsvalid] = useState({
    email: { valid: true, msg: "" },
    name: { valid: true, msg: "" },
  });
  const [notifyShow, setNotifyShow] = useState(false);

  const notify = () => {
    setNotifyShow(true);
    setTimeout(() => {
      setNotifyShow(false);
    }, 5000);
  };

  const submit = async () => {
    setLoading(true);
    let valid = true;

    let validation = {
      email: { valid: true, msg: "Email is ongeldig" },
      name: { valid: true, msg: "" },
    };

    if (
      !email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      validation.email.valid = false;
    }

    if (!name) {
      validation.name.valid = false;
      validation.name.msg = "Verplicht veld";
    }

    setIsvalid(validation);

    Object.values(validation).forEach((value) => {
      if (value.valid == false) {
        valid = value.valid;
      }
    });

    if (valid) {
      let data = {};

      data["name"] = name;
      data["email"] = email;
      data["msg"] = msg;

      api.contact
        .submit(data)
        .then((res) => {
          if (res) {
            setLoading(false);
            notify();
          }
        })
        .catch((e) => {
          setLoading(false);
          console.log(e.response);
        });
    } else {
      setLoading(false);
    }
  };

  return (
    <div>
      {notifyShow && (
        <wb-notification active={0} theme={"succes"}>
          Bericht is succesvol verstuurd. Bedankt voor je feedback.
        </wb-notification>
      )}
      <Container className="mt-4">
        <Row className="d-flex justify-content-center">
          <Col className="col-7">
            <h3>Heb je vragen of suggesties?</h3>
            <p className="mt-2">
              Heb je een vraag, suggesties of wens voor de FietsMonitor? Laat
              het ons weten via dit formulier.
            </p>
          </Col>
        </Row>
        <Row className="mt-3 d-flex justify-content-center">
          <Col className="col-7">
            <Card className="border-0 shadow-sm">
              <CardBody>
                <Form>
                  <FormGroup>
                    <Label for="naam">Naam</Label>
                    <Input
                      id="naam"
                      name="naam"
                      type="text"
                      onChange={(e) => setName(e.target.value)}
                      invalid={!isValid.name.valid}
                    />
                    <FormFeedback>{isValid.name.msg}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="email">Email</Label>
                    <Input
                      id="email"
                      name="email"
                      type="email"
                      onChange={(e) => setEmail(e.target.value)}
                      invalid={!isValid.email.valid}
                    />
                    <FormFeedback>{isValid.email.msg}</FormFeedback>
                  </FormGroup>

                  <FormGroup>
                    <Label for="message">Opmerking</Label>
                    <Input
                      className="mb-3"
                      id="message"
                      name="message"
                      type="textarea"
                      onChange={(e) => setMsg(e.target.value)}
                    />
                  </FormGroup>

                  <div className="d-flex flex-row-reverse">
                    {!loading && (
                      <Button className="text-center" onClick={submit}>
                        verstuur
                      </Button>
                    )}
                    {loading && <div>Laden...</div>}
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
