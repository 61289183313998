import { React } from "react";
import Login from "../components/Login";
// import DataView from "../views/DataView";
import MapView from "../views/MapView";
import Over from "../views/OverView";
import HBMatrix from "../views/HBMatrix";
import Home from "../views/Home";
import ContactView from "../views/ContactView";
import Dashboard from "../views/Dashboard";
// import DataView from "../views/DataView";
import Succes from "../views/Succes";
import { createBrowserHistory } from "history";
export const history = createBrowserHistory();

import AuthStore from "../stores/AuthStore";

import { Router, Switch, Redirect, Route } from "react-router-dom";

export default function Routes() {
  function PrivateRoute({ children, ...rest }) {
    AuthStore.checkIfUserIsLoggedIn();
    return (
      !AuthStore.loading && (
        <Route
          {...rest}
          render={() => {
            return AuthStore.isAuthenticated() ? (
              children
            ) : (
              <Redirect to={"/" + AuthStore.getTargetProject() + "/login"} />
            );
          }}
        />
      )
    );
  }

  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/over">
          <Over />
        </Route>
        <Route exact path="/succes">
          <Succes />
        </Route>
        <Route path="/:projectName/login">
          <Login />
        </Route>
        <PrivateRoute exact path="/:projectName/data">
          <Dashboard />
        </PrivateRoute>
        <PrivateRoute path="/:projectName/contact">
          <ContactView />
        </PrivateRoute>
        <PrivateRoute exact path="/:projectName">
          <MapView />
        </PrivateRoute>
        <PrivateRoute path="/:projectName/matrix">
          <HBMatrix />
        </PrivateRoute>
      </Switch>
    </Router>
  );
}
