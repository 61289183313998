import React from "react";
import Header from "../components/Header.js";
import { Container } from "reactstrap";

const HBMatrix = () => {
  return (
    <div>
      <Header></Header>
      <Container>
        <h3>HB Matrix</h3>
        <p>Map still missing</p>
      </Container>
    </div>
  );
};

export default HBMatrix;
