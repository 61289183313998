import React from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_witteveen from "../../theme";
import api from "../../services/api.js";

am4core.useTheme(am4themes_witteveen);

class VerdelingChart extends React.Component {
  state = { data: {} };
  chart;

  componentDidMount() {
    let chart = am4core.create("chartdiv", am4charts.XYChart);

    function createSeries(field, show) {
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = field;
      series.dataFields.categoryX = "jaar";
      series.name = field;
      series.stacked = true;
      series.hidden = !show;
      series.columns.template.tooltipText = "{name}: [bold]{valueY}[/]";
      series.columns.template.width = am4core.percent(95);
    }

    api.data
      .getVerdeling()
      .then((res) => {
        if (res.status == 200) {
          chart.data = res.data;
          this.setState({ data: res });
          const title = chart.titles.create();
          title.text = "Aantal ritten per jaar";
          title.fontSize = 25;
          title.marginBottom = 30;

          // Create axes
          let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
          categoryAxis.dataFields.category = "jaar";
          categoryAxis.renderer.grid.template.location = 0;
          categoryAxis.renderer.minGridDistance = 20;
          categoryAxis.renderer.cellStartLocation = 0.1;
          categoryAxis.renderer.cellEndLocation = 0.9;

          let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
          valueAxis.min = 0;
          valueAxis.title.text = "Ritten per persoon per dag";

          createSeries("Auto & Auto passagier", false);
          createSeries("Trein", false);
          createSeries("Bus / Tram / Metro", false);
          createSeries("Fiets", true);
          createSeries("Lopen", false);
          createSeries("Overig", false);

          // Add legend
          chart.legend = new am4charts.Legend();
          chart.legend.position = "right";

          this.chart = chart;
        }
      })
      .catch((e) => {
        console.warn(e.response.data.msg);
      });
  }
  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }
  render() {
    return <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>;
  }
}

export default VerdelingChart;
