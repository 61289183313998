import React from "react";
import Header from "../components/Header.js";
import ContactForm from "../components/ContactForm.js";

export default function ContactView() {
  return (
    <div>
      <Header />
      <ContactForm />
    </div>
  );
}
