import { get as getProjection } from "ol/proj";
import { getTopLeft, getWidth } from "ol/extent";
import TileLayer from "ol/layer/Tile";
import WMTSTileGrid from "ol/tilegrid/WMTS";
import WMTS from "ol/source/WMTS";
import { Stroke, Style } from "ol/style.js";

export function createBasemap() {
  const projection = getProjection("EPSG:3857");
  const projectionExtent = projection.getExtent();
  const size = getWidth(projectionExtent) / 256;
  const resolutions = new Array(20);
  const matrixIds = new Array(20);
  for (let z = 0; z < 20; ++z) {
    // generate resolutions and matrixIds arrays for this WMTS
    resolutions[z] = size / Math.pow(2, z);
    matrixIds[z] = z;
  }
  let brt = new TileLayer({
    opacity: 1,
    source: new WMTS({
      url: "https://service.pdok.nl/brt/achtergrondkaart/wmts/v2_0?",
      layer: "grijs",
      matrixSet: "EPSG:3857",
      format: "image/png",
      projection: projection,
      tileGrid: new WMTSTileGrid({
        origin: getTopLeft(projectionExtent),
        resolutions: resolutions,
        matrixIds: matrixIds,
      }),
      style: "default",
      wrapX: true,
    }),
  });
  return brt;
}

const calculateWidth = function (load) {
  return (3 * load) / 1000;
};

export function getStyleAttributes(layer) {
  let styling = { colors: [], labels: [] };
  switch (layer) {
    case "intensiteiten":
      styling.colors = ["#8cd2f7", "#45b6e7", "#008bd1", "#005e7a", "#1a2a2f"];
      styling.labels = [
        "zeer rustig",
        "rustig",
        "gemiddeld",
        "druk",
        "zeer druk",
      ];
      break;
    default:
      break;
  }
  return styling;
}

export function styleLoads(feature, breaks, styleAttributes) {
  let value = feature.get("load");
  let colors = styleAttributes.colors;

  switch (true) {
    case value < breaks[0]:
      return new Style({
        stroke: new Stroke({
          color: colors[0],
          width: calculateWidth(value),
        }),
      });
    case value >= breaks[0] && value < breaks[1]:
      return new Style({
        stroke: new Stroke({
          color: colors[1],
          width: calculateWidth(value),
        }),
      });
    case value >= breaks[1] && value < breaks[2]:
      return new Style({
        stroke: new Stroke({
          color: colors[2],
          width: calculateWidth(value),
        }),
      });
    case value >= breaks[2] && value < breaks[3]:
      return new Style({
        stroke: new Stroke({
          color: colors[3],
          width: calculateWidth(value),
          zindex: 10,
        }),
      });
    case value >= breaks[3] && value < breaks[4]:
      return new Style({
        stroke: new Stroke({
          color: colors[4],
          width: calculateWidth(value),
          zindex: 10,
        }),
      });

    default:
      return new Style({
        stroke: new Stroke({
          color: colors[5],
          width: calculateWidth(value),
        }),
      });
  }
}
