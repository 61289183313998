import React, { useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import api from "../../services/api.js";
import am4themes_witteveen from "../../theme";

am4core.useTheme(am4themes_witteveen);

export default function MotievenChart() {
  useEffect(() => {
    let chart = am4core.create("motieven_chart", am4charts.XYChart);

    const title = chart.titles.create();
    title.text = "Ritten naar verplaatsingsmotief per woongemeente";
    title.fontSize = 25;
    title.marginBottom = 30;

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "gemeentenaam";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 20;
    categoryAxis.renderer.cellStartLocation = 0.1;
    categoryAxis.renderer.cellEndLocation = 0.9;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.title.text = "Aantal verplaatsingen per persoon per dag";

    function createSeries(field, show) {
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = field;
      series.dataFields.categoryX = "gemeentenaam";
      series.name = field;
      series.stacked = true;
      series.hidden = !show;
      series.columns.template.tooltipText = "{name}: [bold]{valueY}[/]";
      series.columns.template.width = am4core.percent(95);
    }

    createSeries("Zakelijk bezoek in werksfeer", true);
    createSeries("Diensten en persoonlijke verzorging", true);
    createSeries("Visite / logeren", true);
    createSeries("Winkelen / boodschappen doen", true);
    createSeries("Onderwijs / cursus volgen", true);
    createSeries("Toeren / wandelen", true);
    createSeries("Van en naar werk", true);
    createSeries("Overig", true);

    // Add legend
    chart.legend = new am4charts.Legend();
    chart.legend.position = "right";

    api.data
      .getMotieven()
      .then((res) => {
        if (res.status == 200) {
          chart.data = res.data;
        }
      })
      .catch((e) => {
        console.warn(e.response.data.msg);
      });

    return () => {
      if (chart) {
        chart.dispose();
      }
    };
  }, []);

  return (
    <div id="motieven_chart" style={{ width: "100%", height: "500px" }}></div>
  );
}
