import * as am4core from "@amcharts/amcharts4/core";

export default function am4themes_witteveen(target) {
  if (target instanceof am4core.ColorSet) {
    target.list = [
      am4core.color("#0A5928"),
      am4core.color("#7BC2BA"),
      am4core.color("#1A4657"),
      am4core.color("#005E7B"),
      am4core.color("#352A52"),
      am4core.color("#7B1351"),
      am4core.color("#DD8B91"),
      am4core.color("#C2E7F1"),
    ];
  }
}
