import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { Redirect, useParams } from "react-router-dom";
import Header from "./Header.js";
import authStore from "../stores/AuthStore";

const Login = observer(() => {
  const { projectName } = useParams();
  const [isLoggedIn, setisLoggedIn] = useState(false);
  const [password, setPassword] = useState(null);
  const [msg, setMsg] = useState(null);

  const login = () => {
    let cb = (res) => {
      if (res.succes == true) {
        setisLoggedIn(true);
      }

      if (res.succes == false) {
        setMsg(res);
        setTimeout(() => {
          setMsg("");
        }, 3000);
      }
    };

    authStore.signin({ projectname: projectName, password: password }, cb);
  };

  if (isLoggedIn == true) {
    return <Redirect to={`/${projectName}/`} />;
  }

  const notification = msg && (
    <wb-notification
      class=""
      icon={msg.code === 401 ? "warning_amber" : "error"}
      active="0"
      inline="true"
      close="false"
      theme={msg.code === 401 ? "warning" : "danger"}
    >
      {msg.code === 401
        ? msg.message
        : "Er is iets verkeerds gegaan. Het beheer team is op de hoogte gebracht. Probeer het later nog eens of neem contact met ons op via: "}
      {msg.code === 401 ? null : (
        <a
          href="mailto:fietsmonitor@witteveenbos.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          fietsmonitor@witteveenbos.com
        </a>
      )}
    </wb-notification>
  );

  return (
    <div>
      <Header />
      <Container>
        <Row>
          <Col xs="6" md="6" lg="6" className="mb-3">
            <h4 className="mt-4">Aanmelden voor project {projectName}</h4>
          </Col>
        </Row>

        <Row>
          <Col xs="6" md="6" lg="6">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                login();
              }}
            >
              <FormGroup>
                <Label for="password">Wachtwoord</Label>
                <Input
                  type="password"
                  name="password"
                  id="projectPassword"
                  placeholder=""
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </FormGroup>
              <Button>inloggen</Button>
            </Form>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>{notification}</Col>
        </Row>
      </Container>
    </div>
  );
});

export default Login;
