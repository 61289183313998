import React from "react";
import Header from "../components/Header.js";
import VerdelingChart from "../components/charts/VerdelingChart.js";
import MotievenChart from "../components/charts/MotievenChart.js";
import RitLengteChart from "../components/charts/RitLengteChart.js";
import { Container, Row, Col, Card, CardBody, CardText } from "reactstrap";

export default function Dashboard() {
  return (
    <div>
      <Header />
      <Container className="mt-4">
        <Row>
          <Col>
            <h3>Hoeveel wordt er gefietst?</h3>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Card className="border-0 shadow-sm">
              <CardBody>
                <MotievenChart />
                <CardText className="pt-3 d-flex justify-content-center">
                  Ontwikkelingen van aantal verplaatsingen per persoon per
                  gemeente naar verplaatsingsmotief. Bron: OViN en ODiN (CBS).
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Card className="border-0 shadow-sm">
              <CardBody>
                <VerdelingChart />
                <CardText className="pt-4 d-flex justify-content-center">
                  Ontwikkelingen van aantal verplaatsingen per persoon per jaar
                  naar vervoerswijze. Bron: OViN en ODiN (CBS).
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col>
            <h3 className="mt-4">Hoe ver wordt er gefietst?</h3>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <Card className="border-0 shadow-sm">
              <CardBody>
                <RitLengteChart />
                <CardText className="pt-3 d-flex justify-content-center">
                  Verdeling van aandeel verplaatsingen over vervoersmiddelen per
                  afstandsklasse. Bron: OViN en ODiN (CBS).
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
