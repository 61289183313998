import React from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_witteveen from "../../theme";
import api from "../../services/api.js";

am4core.useTheme(am4themes_witteveen);

class RitLengteChart extends React.Component {
  state = { data: {} };
  chart;

  componentDidMount() {
    let chart = am4core.create("line-chart-div", am4charts.XYChart);

    function createSeries(field) {
      let series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = field;
      series.dataFields.categoryX = "verplaatsingsafstand";
      series.strokeWidth = 2;
      series.name = field;
      series.showOnInit = true;
      series.calculatePercent = true;
      series.tooltipText = "{name}: [bold]{valueY}[/]";

      const bullet = series.bullets.push(new am4charts.CircleBullet());
      bullet.circle.strokeWidth = 2;
    }

    api.data
      .getDistribution()
      .then((res) => {
        if (res.status == 200) {
          this.setState({ data: res.data });
          chart.data = res.data;
          const title = chart.titles.create();
          title.text = "Rit lengte verdeling";
          title.fontSize = 25;
          title.marginBottom = 30;

          // Create axes
          let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
          categoryAxis.dataFields.category = "verplaatsingsafstand";
          categoryAxis.title.text = "Verplaatsingsafstand";
          categoryAxis.renderer.minGridDistance = 20;

          let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
          valueAxis.min = 0;
          valueAxis.title.text = "Aandeel van totaal";
          valueAxis.renderer.labels.template.adapter.add(
            "text",
            function (text) {
              return Math.round(text * 100, 2) + "%";
            }
          );

          createSeries("Auto & Auto passagier");
          createSeries("Trein");
          createSeries("Bus / Tram / Metro");
          createSeries("Fiets");
          createSeries("Lopen");
          createSeries("Overig");

          // Add legend
          chart.legend = new am4charts.Legend();
          chart.cursor = new am4charts.XYCursor();
          chart.legend.position = "right";
          this.chart = chart;
        }

        if (res.status == 204) {
          console.warn(res.statusText);
        }
      })
      .catch((e) => {
        console.warn(e.response.data.msg);
      });
  }
  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }
  render() {
    return (
      <div id="line-chart-div" style={{ width: "100%", height: "500px" }}></div>
    );
  }
}

export default RitLengteChart;
