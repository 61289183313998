import React, { useEffect, useState } from "react";
import Header from "../components/Header.js";
import DashboardMap from "../components/map/DashboardMap.js";
import api from "../services/api.js";

export default function ProjectMap() {
  const [mapConfig, setMapConfig] = useState(null);

  useEffect(() => {
    Promise.all([api.project.getConfig(), api.project.getGemeenteCodes()])
      .then((res) => {
        if (res) {
          let mapData = res[0].data;
          mapData.gemeenteCodes = res[1].data;
          setMapConfig(mapData);
        }
      })
      .catch((e) => {
        console.warn(e.response.data.msg);
      });
  }, []);
  return (
    <div>
      <Header />
      {mapConfig && <DashboardMap config={mapConfig} />}
    </div>
  );
}
