import React, { useEffect } from "react";
import ProjectAreaMap from "../components/map/ProjectAreaMap.js";
import Header from "../components/Header.js";

export default function Home() {
  useEffect(() => {}, []);
  return (
    <div style={{ heigt: "100%" }}>
      <Header />
      <ProjectAreaMap />
    </div>
  );
}
