import axios from "axios";
import { history } from "../routes/routes.js";
import AuthStore from "../stores/AuthStore";

const config = {
  headers: {
    "Content-Type": "application/json",
    Accepts: "application/json",
  },
};

const api = axios.create({
  baseURL: process.env.REACT_APP_ROOT_API,
});

api.interceptors.request.use(function (config) {
  if (localStorage.getItem("fietsmonitor")) {
    const token =
      JSON.parse(localStorage.getItem("fietsmonitor"))["token"] || null;
    config.headers.Authorization = `${token}`;
  }

  return config;
});

api.interceptors.response.use(undefined, (error) => {
  let target_project = error.response.data.target_project;

  switch (error.response.status) {
    case 401:
      AuthStore.signout();
      history.push(`/${target_project}/login`);
      break;
    case 403:
      history.push("/");
      break;
    default:
      break;
  }

  return Promise.reject(error);
});

const requests = {
  post: (url, data) => api.post(`${url}`, data, config).then((res) => res),
};

const project = {
  create: (project_id) => {
    return requests.post("/projects/create", project_id);
  },
  validateProjectArea: (project_area) => {
    return requests.post("/projects/validatearea", project_area);
  },
  getConfig: () => {
    return requests.post("/projects/config", AuthStore.getTargetProject());
  },
  getGemeenteCodes: () => {
    return requests.post(
      "/projects/gemeentecodes",
      AuthStore.getTargetProject()
    );
  },
};

const data = {
  getMotieven: () => {
    return requests.post("/data/motieven", AuthStore.getTargetProject());
  },
  getDistribution: () => {
    return requests.post("/data/distributie", AuthStore.getTargetProject());
  },
  getVerdeling: () => {
    return requests.post("/data/verdeling", AuthStore.getTargetProject());
  },
};

const contact = {
  submit: (data) => {
    return requests.post("/contact/submit", data);
  },
};

export default {
  project,
  data,
  contact,
};
