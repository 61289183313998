import React, { useState } from "react";
import {
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  FormFeedback,
  Input,
  Label,
} from "reactstrap";

import { useHistory } from "react-router-dom";
import api from "../../services/api.js";

const style = {
  position: "absolute",
  top: "100px",
  left: "10px",
  zIndex: "2",
  width: "350px",
};

export default function RequestForm(props) {
  let history = useHistory();
  const [email, setEmail] = useState(null);
  const [name, setName] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isValid, setIsvalid] = useState({
    email: { valid: true, msg: "" },
    name: { valid: true, msg: "" },
    project_area: { valid: true, msg: "" },
  });

  const submit = async () => {
    setLoading(true);
    let valid = true;

    let validation = {
      email: { valid: true, msg: "Email is ongeldig" },
      name: { valid: true, msg: "" },
      project_area: { valid: true, msg: "" },
    };
    if (
      !email ||
      !email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      validation.email.valid = false;
    }

    if (name) {
      let resp = await fetch("/projects/validatename", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accepts: "application/json",
        },
        body: JSON.stringify({ data: name }),
      });

      if (resp.status == 409) {
        validation.name.valid = false;
        validation.name.msg = "Projectnaam is reeds in gebruik";
      }

      if (resp.status == 401) {
        validation.name.valid = false;
        validation.name.msg = "Validatie kan niet uitgevoerd worden";
      }

      if (resp.status == 500) {
        validation.name.valid = false;
        validation.name.msg = "Foutmelding server";
      }
    } else {
      validation.name.valid = false;
      validation.name.msg = "Verplicht veld";
    }

    if (Object.keys(props.project_area).length < 1) {
      validation.project_area.valid = false;
      validation.project_area.msg = "Selecteer één gemeenten";
    }

    setIsvalid(validation);

    Object.values(validation).forEach((value) => {
      if (value.valid == false) {
        valid = value.valid;
      }
    });

    if (valid) {
      let data = {};

      data["gebied"] = Object.values(props.project_area)[0].get("gemeentenaam");
      data["name"] = name;
      data["email"] = email;

      api.project
        .create(data)
        .then((res) => {
          if (res) {
            setLoading(false);
            history.push("/succes");
          }
        })
        .catch((e) => {
          setLoading(false);
          console.log(e);
        });
    } else {
      setLoading(false);
    }
  };

  return (
    <Card style={style}>
      <CardBody>
        <h5 className="wb-mb-2">Welkom bij de FietsMonitor</h5>
        <ol>
          <li className="wb-mb-1">Selecteer een gemeente op de kaart</li>
          <li className="wb-mb-1">
            Bedenk een projectnaam en vul deze hier in (dit kan een bestaand
            project zijn maar kan ook een fictieve naam zijn. Deze naam wordt
            gebruikt in de url van jouw FietsMonitor-project)
          </li>
          <li className="wb-mb-1">
            Vul je e-mail adres in (Dit e-mail adres wordt alleen gebruikt voor
            het versturen van de inlogcode, verder slaan we dit niet op. Wanneer
            je een fictief adres invult, kunnen we je geen inlogcode sturen)
          </li>
          <li className="wb-mb-1">
            Klik op de verstuur-knop &#39;Vraag aan&#39;
          </li>
          <li className="wb-mb-1">
            Binnen ongeveer 10 minuten ontvang je een e-mail met een link en
            inlogcode om de fietsstromen in jouw projectgebied te zien.
          </li>
        </ol>
        <Form>
          {!isValid.project_area.valid && (
            <div
              style={{
                paddingBottom: "20px",
                color: "#dc3545",
                fontSize: "83%",
              }}
            >
              {isValid.project_area.msg}
            </div>
          )}
          <FormGroup>
            <Label for="project-naam">Projectnaam</Label>
            <Input
              id="project-naam"
              name="projectnaam"
              type="text"
              onChange={(e) => setName(e.target.value)}
              invalid={!isValid.name.valid}
            />
            <FormFeedback>{isValid.name.msg}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="email">Email</Label>
            <Input
              id="email"
              name="email"
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              invalid={!isValid.email.valid}
            />
            <FormFeedback>{isValid.email.msg}</FormFeedback>
          </FormGroup>

          {!loading && <Button onClick={submit}>Vraag aan</Button>}
          {loading && <div>Laden...</div>}
        </Form>
      </CardBody>
    </Card>
  );
}
