import { React } from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import MapLegendItems from "./MapLegendItems.js";

const legendStyle = {
  position: "absolute",
  padding: "20px",
  top: "100px",
  left: "10px",
  zIndex: "2",
  width: "350px",
};

const legendTitleStyle = {
  display: "flex",
};

const legendUnitStyle = {
  fontSize: "small",
  opacity: 0.7,
  paddingTop: "9px",
};

export default function MapLegend(props) {
  let layer = props.mapLayers[0];

  return (
    <Card className="shadow-sm" style={legendStyle}>
      <CardTitle tag="h6">Legenda</CardTitle>

      {layer && (
        <CardBody style={{ padding: "4px" }}>
          <div style={legendTitleStyle}>
            <h5 className="pr-2">{layer.title}</h5>
            <span style={legendUnitStyle}>({layer.data_unit})</span>
          </div>
          <MapLegendItems map_layer={layer} />
        </CardBody>
      )}
    </Card>
  );
}
