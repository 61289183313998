import React from "react";
import Header from "../components/Header.js";
import { Container, Row, Fade } from "reactstrap";
import Fiets from "./fiets.gif";

export default function Succes() {
  return (
    <div>
      <Header />
      <Container className="mt-4">
        <Fade>
          <Row className="d-flex  justify-content-center">
            <h2>
              De FietsMonitor is succesvol aangevraagd voor je projectgebied
            </h2>

            <img style={{ height: "450px" }} src={Fiets} alt="loading..." />

            <h4>Je ontvangt een email zodra deze beschikbaar is</h4>
          </Row>
        </Fade>
      </Container>
    </div>
  );
}
