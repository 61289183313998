import { runInAction, makeAutoObservable } from "mobx";
import { history } from "../routes/routes.js";

class AuthStore {
  loading = false;
  project = { projectid: null, slug: "" };
  token = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  updateProject = (key, value) => {
    this.project[key] = value;
  };

  setProject = (project) => {
    this.project = project;
  };

  signin = async (credentials, cb) => {
    this.loading = true;
    fetch(`/user/signin`, {
      method: "POST",

      headers: {
        "Content-Type": "application/json",
        Accepts: "application/json",
      },
      body: JSON.stringify(credentials),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res.status;
        }
      })
      .then((json) => {
        runInAction(async () => {
          this.setProject(json);
          localStorage.setItem("fietsmonitor", JSON.stringify(json));
          this.loading = false;
        });

        cb({ succes: true, message: "ok" });
      })
      .catch((e) => {
        if (e == 401) {
          this.loading = false;
          cb({
            succes: false,
            code: 401,
            message:
              "Ongeldige gebruikersnaam of wachtwoord. Probeer het opnieuw.",
          });
        }

        if (e == 500) {
          this.loading = false;
          cb({
            succes: false,
            code: 500,
            message: "Geen verbinding met server. Probeer het later opnieuw",
          });
        }
      });
  };

  checkIfUserIsLoggedIn = () => {
    let current_project = localStorage.getItem("fietsmonitor");

    if (current_project) {
      this.setProject(JSON.parse(current_project));
    } else {
      history.push(`/${location.pathname.split("/")[1]}/login`);
    }
  };

  signout = () => {
    this.token = undefined;
    this.project = { projectid: null, name: "" };
    localStorage.removeItem("fietsmonitor");
  };

  getTargetProject = () => {
    return {
      target_project: location.pathname.split("/")[1],
      project_id: this.project.projectid,
    };
  };

  isAuthenticated() {
    if (this.project) {
      return true;
    } else {
      return false;
    }
  }
}

export default new AuthStore();
