import React, { useRef, useEffect, useState } from "react";
import "ol/ol.css";

// OpenLayers
import Map from "ol/Map.js";
import View from "ol/View.js";
import MVT from "ol/format/MVT.js";
import { Style, Fill } from "ol/style.js";
import VectorTileLayer from "ol/layer/VectorTile.js";
import VectorTileSource from "ol/source/VectorTile.js";
import WKT from "ol/format/WKT";

// Components
import MapControls from "./MapControls.js";
import MapLegend from "./MapLegend.js";
import {
  createBasemap,
  styleLoads,
  getStyleAttributes,
} from "../../utils/mapUtils.js";
import AuthStore from "../../stores/AuthStore";
import ProgressBar from "./ProgressBar.js";

const mapStyle = {
  position: "absolute",
  height: "100%",
  top: "0",
  left: "0",
  bottom: "0",
  right: "0",
};

const selectionStyle = new Style({
  fill: new Fill({
    color: "rgba(0, 0, 0, 0.2)",
  }),
});

export default function DashboardMap(props) {
  const mapEl = useRef(null);
  const [map, setMap] = useState(null);
  const [mapLayers, setMapLayers] = useState([]);
  const [source, SetSoruce] = useState(null);

  useEffect(() => {
    let nonFocusedMunicipalitiesBackground = new VectorTileLayer({
      declutter: false,
      zindex: 30,
      source: new VectorTileSource({
        format: new MVT(),
        url: `${process.env.REACT_APP_TILESERVER_URL}/fm.gemeenten/{z}/{x}/{y}.pbf`,
      }),
      style: function (feature) {
        if (feature.get("gemeentecode") !== props.config.gemeenteCodes[0]) {
          return selectionStyle;
        }
      },
    });

    let basemap = createBasemap();
    let breaks = props.config.jenkins_bins;

    let intensity_layer = {
      title: "Fietsstromen",
      data_unit: "fietsers/dag",
      values: props.config.jenkins_bins,
      style_attributes: getStyleAttributes("intensiteiten"),
    };

    setMapLayers((oldArray) => [...oldArray, intensity_layer]);

    let vectorSource = new VectorTileSource({
      format: new MVT(),
      url: `${process.env.REACT_APP_TILESERVER_URL}/public.intensiteiten_fn/{z}/{x}/{y}.pbf?p_project_id=${AuthStore.project.projectid}`,
    });

    vectorSource.on("error", (error) => {
      console.log("vectorSource failed", error.test);
    });

    SetSoruce(vectorSource);

    let network_loads = new VectorTileLayer({
      declutter: false,
      zindex: 30,
      source: vectorSource,
      style: (feature) => {
        return styleLoads(feature, breaks, intensity_layer.style_attributes);
      },
    });

    const wkt = new WKT();
    let mapCenter = wkt.readGeometry(props.config.wkt_center).getCoordinates();

    let map = new Map({
      target: mapEl.current,
      layers: [basemap, nonFocusedMunicipalitiesBackground, network_loads],
      view: new View({
        center: mapCenter,
        zoom: 12,
      }),
    });

    map.set("mapCenter", mapCenter);

    setMap(map);
  }, []);

  return (
    <div>
      <MapControls map={map} tools={{ zoom: true, center: true }} />
      {mapLayers && <MapLegend mapLayers={mapLayers} />}
      <div style={mapStyle} ref={mapEl}></div>
      {source && <ProgressBar source={source} />}
    </div>
  );
}
